<template>
    <b-modal id="streetModalEdit" title="Изменить данные района" size="md" hide-footer no-enforce-focus :no-close-on-backdrop="true" @show="opened">
        <b-row>
            <b-col cols="12">
                 <b-row>
                    <b-col cols="6">
                        <label>Ваша улица</label>
                        <b-input v-model="street.name" placeholder="Улица" />
                    </b-col>
                    <b-col cols="6">
                        <label>Население пукт</label>
                        <v-select
                            v-model="street.village_id"
                            placeholder="Район"
                            label="name"
                            :options="districts"
                            :reduce="options => options.id"
                            class="select-size-md"
                        />
                    </b-col>
                    <b-col cols="6" class="mt-2">
                        <label>Тип улицы</label>
                        <v-select
                            v-model="street.street_type_id"
                            placeholder="Тип улицы"
                            label="name"
                            :options="street_types"
                            :reduce="street_type => street_type.id"
                            class="select-size-md"
                        />
                    </b-col>
                    <b-col>
                        <b-form-checkbox class="mt-2" v-model="street.is_active" checked="true" name="check-button" switch inline>
                            {{this.street.is_active ? 'Активный' : ' Не активный'}}
                        </b-form-checkbox>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col cols="12" class="d-flex justify-content-between">
                        <b-button
                        :disabled="submitButtonDisabled"
                        class="mt-2 col-md-5"
                        variant="primary"
                        @click="editStreet()">
                            Изменить
                        </b-button>
                        <b-button class="mt-2 col-md-5" variant="secondary" @click="btnCancel">
                            Отмена
                        </b-button>
                    </b-col>
                    
                </b-row>
            </b-col>
        </b-row>
        <map-vue @latLng="latLng" />
    </b-modal>
</template>
<script>
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import mapVue from '../../Map/map.vue'

export default {
    props: ['id'],
    components: {
        vSelect,
        ToastificationContent,
        mapVue,
    },
    data(){
        return {
            street: [],
            districts: undefined,
            street_types: [],
            submitButtonDisabled: false,
        }
    },
    mounted(){
    },
    methods: {
        opened(){
            this.$http
                .get(`addresses/streets/${this.id.id}/edit`)
                .then(res => {
                    this.street = res.data
                    this.street.is_active = this.street.is_active == 1 ? true : false
            })

            this.$http
                .get(`addresses/districts`)
                .then(res => {
                    this.districts = res.data
            })
            this.$http
            .get('addresses/street-types')
            .then(res =>{
                this.street_types = res.data.street_types
            })
        },
        editStreet(){
            this.submitButtonDisabled = true
            if (this.street.is_active == true) {
                this.street.is_active = 1
            } else {
                this.street.is_active = 0
            }
            this.$http
                .patch(`addresses/streets/${this.id.id}`, this.street)
                .then(res => {
                    this.btnCancel()
                    // this.$bvModal.hide('streetModalEdit')
                    this.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                            title: 'Успех!',
                            icon: 'CheckIcon',
                            variant: 'success',
                            text: `Улица успешно изменена!`,
                        },
                    })
                    this.$emit('refresh')
                })
                .catch(err => {
                    this.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                        title: 'Некорректные данные!',
                        icon: 'XIcon',
                        variant: 'danger',
                        text: err.response.data.errors,
                        },
                    })
                })
                .finally(()=>{
                    this.submitButtonDisabled = false
                })
        },
        latLng(latLng){
            this.street.name = latLng.address.road
        },
        btnCancel(){
            this.$bvModal.hide('streetModalEdit')
        }
    }

}
</script>