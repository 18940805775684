<template>
    <b-modal :id="$route.name + 'CreateModal'" title="Добавить улицу" size="md" hide-footer no-enforce-focus :no-close-on-backdrop="true" @show="opened">
        <b-row>
            <b-col cols="12">
                <b-row>
                    <b-col cols="6">
                        <label>Ваша улица</label>
                        <b-input v-model="street.name" placeholder="Улица" />
                    </b-col>
                    <b-col cols="6">
                        <label>Ваша район</label>
                        <v-select
                            v-model="district_select"
                            @input="setSelected"
                            placeholder="Район"
                            label="name"
                            :options="districts"
                            class="select-size-md"
                        />
                    </b-col>
                    <b-col cols="6" class="mt-2">
                        <label>Тип улицы</label>
                        <v-select
                            v-model="street.street_type_id"
                            placeholder="Тип улицы"
                            label="name"
                            :options="street_types"
                            :reduce="street_type => street_type.id"
                            class="select-size-md"
                        />
                    </b-col>
                </b-row>
                <b-row>
                    <b-col cols="12" class="d-flex justify-content-between">
                        <b-button
                        :disabled="submitButtonDisabled"
                        class="mt-2 col-md-5"
                        variant="primary"
                        @click="createStreet()">
                            Добавить
                        </b-button>
                        <b-button class="mt-2 col-md-5" variant="secondary" @click="btnCancel">
                            Отмена
                        </b-button>
                    </b-col>
                </b-row>
            </b-col>
        </b-row>
    </b-modal>
</template>
<script>
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
    components: {
        vSelect,
        ToastificationContent
    },
    props: [],
    data(){
        return {
            street: {
                name: '',
                district_id: '',
                street_type_id: ''
            },
            district_select: undefined,
            districts: [],
            street_types: [],
            submitButtonDisabled: false,
        }
    },
    methods: {
        createStreet(){
            this.submitButtonDisabled = true
            this.$http
                .post('addresses/streets', this.street)
                .then(res => {
                    this.btnCancel()
                    // this.$bvModal.hide('streetModal')
                    this.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                        title: 'Успех!',
                        icon: 'CheckIcon',
                        variant: 'success',
                        text: `Улица успешно добавлена!`,
                        },
                    })
                    this.$bvModal.hide(this.$route.name + 'CreateModal')
                    this.$emit('refresh')
                })
                .catch(err => {
                    this.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                        title: 'Некорректные данные!',
                        icon: 'XIcon',
                        variant: 'danger',
                        text: err.response.data.errors,
                        },
                    })
                })
                .finally(()=>{
                    this.submitButtonDisabled = false
                })
        },
        opened(){
            this.$http
                .get('addresses/districts').then(res => {
                    this.districts = res.data
                })
            this.$http
            .get('addresses/street-types')
            .then(res =>{
                this.street_types = res.data.street_types
            })
            this.clearData()
        },
        setSelected(){
            this.street.district_id = this.district_select.id
        },
        btnCancel(){
            this.$bvModal.hide(this.$route.name + 'CreateModal')
            this.clearData()
        },
        clearData(){
            this.street = {
                name: '',
                district_id: '',
            },
            this.district_select = undefined,
            this.districts = []
        }
    }
}
</script>